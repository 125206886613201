import React, {useEffect, useState, useCallback, Fragment} from "react";
import {Modal, Button, Row, Col, Form} from "react-bootstrap";
import {logout} from "../../../utilities/cookieAuth";
import {getAgreement} from "../../../utilities/agreementMode";
import {API_URL, UPLOAD_TERMS} from "../../../utilities/constants";
import Agreement from "../../../components/General/Agreement";
import AddButton from "./AddButton";
import ItemInput from "./ItemInput";
import IconDropdown from "./IconDropdown";
import Toast from "../../../components/General/Toast";
import Indent from "./Indent";
import PropTypes from "prop-types";
import Error from "../../../components/General/Error";
import "./ConstructCardModal.css";

// Modal used for creating and editing cards
function ConstructCardModal(props) {

  const [counter, setCounter] = useState(0);
  const [pureCounter, setPureCounter] = useState(0);
  const [title, setTitle] = useState("");
  const [format, setFormat] = useState(0);
  const [items, setItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [basicIcons, setBasicIcons] = useState([]);
  const [imageIcons, setImageIcons] = useState([]);
  const [linkIcons, setLinkIcons] = useState([]);
  const [checked, setChecked] = useState(0);
  const [copyToast, setCopyToast] = useState(false);
  const [cardTitleMode, setCardTitleMode] = useState("");
  const [selectedItems, setSelectedItems] = useState(0);
  const [imageAgreement, setImageAgreement] = useState(getAgreement("image"));
  const [showAgreement, setShowAgreement] = useState(false);
  const [controlHeld, setControlHeld] = useState(false);
  const [imageTerms] = useState(UPLOAD_TERMS);

  // setup card data
  useEffect(() => {

    // sort icons into three categories, general items, images, and links
    function sortIcons() {
      const gen = [];
      const images = [];
      const links = [];
      for (let i = 0; i < props.iconSet.length; i++) {
        if (props.iconSet[i].groupIndex === 1 || props.iconSet[i].groupIndex === 4) {
          gen.push(props.iconSet[i]);
        } else if (props.iconSet[i].groupIndex === 2) {
          images.push(props.iconSet[i]);
        } else if (props.iconSet[i].groupIndex === 3) {
          links.push(props.iconSet[i]);
        }
      }
      setBasicIcons(gen);
      setImageIcons(images);
      setLinkIcons(links);
    }

    sortIcons(props.iconSet);

    // if we are a new card, just return
    if (!props.edit) {
      return;
    }

    // Get data from the published or edited card
    let newItems = [];
    let itemData = {};
    let newCounter = 0;
    let itemSet = [];
    if (props.card.tempItems.length) {
      itemSet = generateItems(props.card.tempItems);
    } else {
      itemSet = generateItems(props.card.items);
    }

    // Push items from props to state
    itemSet.forEach((item) => {
      itemData = {};
      itemData.counterId = newCounter + 1;
      itemData.itemId = item.itemId;
      itemData.contentText = item.contentText;
      itemData.contentLabel = item.contentLabel;
      itemData.contentUrl = item.contentUrl;
      itemData.learnMoreUrl = item.learnMoreUrl;
      itemData.indentation = item.indentation;
      itemData.iconType = item.iconType;
      itemData.contentMode = item.contentMode;
      itemData.groupIndex = item.groupIndex;
      itemData.internal = item.internal;
      itemData.inline = item.inline;
      itemData.sourceId = item.sourceId;
      itemData.current = 1;
      itemData.created = item.created;
      newItems.push(itemData);
      newCounter++;
    });

    newItems = scanIndentation(newItems);
    setItems(newItems);
    setCounter(newCounter);
    setErrorMessage("");
    if (props.card.tempCardId) {
      setTitle(props.card.tempTitle);
      setFormat(props.card.tempCardType);
    } else {
      setTitle(props.card.title);
      setFormat(props.card.cardType);
    }

    // if the current card is internal, then the modals has
    // the internal setting checked when opened
    let currentCardType = 0;

    // get the card type
    if (props.card.tempCardId) {
      currentCardType = props.card.tempCardType;
    } else {
      currentCardType = props.card.cardType;
    }

    // card types that are greater than or equal to 10 are always internal
    setChecked(currentCardType >= 10);
  }, [props.show, props.card, props.edit, props.iconSet]);

  // make sure that the next assigned ID is always larger than the most recent item
  // the reason that counter is not reliable, is due to the ability to delete items
  useEffect(() => {
    let pure = 0;
    for (let i = 0; i < items.length; i++) {
      if (items[i].counterId > pure) {
        pure = items[i].counterId;
      }
    }
    setPureCounter(pure + 1);
  }, [items, items.length, counter]);

  // add CTRL key down listener
  useEffect(() => {
    document.addEventListener("keydown", ctrlDown, false);
    return () => {
      document.removeEventListener("keydown", ctrlDown, false);
    };
  }, []);

  // add CTRL key up listener
  useEffect(() => {
    document.addEventListener("keyup", ctrlUp, false);
    return () => {
      document.removeEventListener("keyup", ctrlUp, false);
    };
  }, []);

  // Clear error messages whenever the modal is opened or closed
  useEffect(() => {
    setErrorMessage("");
  }, [props.show]);

  // Set the CTRL key held state to false
  const ctrlUp = useCallback((event) => {
    if (event.keyCode === 17) {
      setControlHeld(false);
    }
  }, []);

  // Set the CTRL key held state to true
  const ctrlDown = useCallback((event) => {
    if (event.keyCode === 17) {
      setControlHeld(true);
    }
  }, []);

  // Makes a copy of the items in the props
  function generateItems(itemList) {
    const itemArray = [];
    itemList.map((item) => {
      itemArray.push(item);
      return null;
    });
    return itemArray;
  }

  // checks if the current item is selected
  function isSelected(item) {
    for (let i = 0; i < selectedItems.length; i++) {
      if (item.counterId === selectedItems[i]) {
        return true;
      }
    }
    return false;
  }

  // reorders the list of selected items in relation to the real list of items
  function reorderSelected(newItems) {
    const copy = [...items];
    const newSelected = [];

    for (let i = 0; i < copy.length; i++) {
      for (let j = 0; j < newItems.length; j++) {
        if (copy[i].counterId === newItems[j]) {
          newSelected.push(newItems[j]);
        }
      }
    }

    setSelectedItems(newSelected);
  }

  // Keeps track of the current number of input fields
  function incrementCounter(groupIndex) {
    const newCounter = counter;
    const pureId = pureCounter;
    const key = (newCounter).toString();
    let copy = [...items];

    let newIconType = null;
    let newIndent = 0;

    // get the icon and indentation of the previous item if possible
    if (items.length) {
      newIndent = items[items.length - 1].indentation;
      if (items[items.length - 1].groupIndex === groupIndex) {
        newIconType = items[items.length - 1].iconType;
      }
    }

    // if this is a link, then get the previous items link mode
    let newContentMode = -1;
    if (items.length && items[items.length - 1].groupIndex === 3) {
      newContentMode = items[items.length - 1].contentMode;
    }

    // Init new empty item
    copy[key] = {};
    copy[key].counterId = pureId + 1;
    copy[key].contentText = "";
    copy[key].contentLabel = "";
    copy[key].contentUrl = "";
    copy[key].learnMoreUrl = "";
    copy[key].iconType = newIconType;
    copy[key].groupIndex = groupIndex;
    copy[key].contentMode = newContentMode;
    copy[key].indentation = newIndent;
    copy[key].inline = 0;
    copy[key].internal = 0;
    copy[key].sourceId = 0;
    copy[key].created = new Date();

    // Make sure the indentation is up to date
    copy = scanIndentation(copy);

    setItems(copy);
    setCounter(newCounter + 1);
    setPureCounter(pureCounter + newCounter + 1);
  }

  // Change the indentation level of the selected item(s)
  function changeIndent(amount) {
    let copy = [...items];

    // indent each selected item
    for (let i = 0; i < selectedItems.length; i++) {
      const counterId = selectedItems[i];
      let arrayIndex = -1;

      // Find the index of this item
      for (let j = 0; j < copy.length; j++) {
        if (copy[j].counterId === counterId) {
          arrayIndex = j;
          break;
        }
      }

      // If we can not find the index then return
      if (arrayIndex === -1) {
        console.error("Unable to find item to indent");
        continue;
      }

      // If the current index is the first item on the card return
      if (arrayIndex === 0) {
        console.error("This item cannot be indented");
        continue;
      }

      // If we are indenting an inline item, we will instead
      // focus on the first inline item in the row
      if (copy[arrayIndex].inline) {
        let found = false;
        for (let j = arrayIndex; j > 0; j--) {
          if (!copy[j - 1].inline) {
            arrayIndex = j;
            found = true;
            break;
          }
        }

        // if we can't find the first item in the row then return
        if (!found) {
          console.error("Unable to indent this inline item");
          continue;
        }
      }

      // If we are removing indentation do that and return
      if (amount === -1) {

        // Lower our indentation level
        if (copy[arrayIndex].indentation > 0) {
          copy[arrayIndex].indentation += -1;
        }

      } else {

        // Check if we should be able to update our indentation and by how much
        const prevIndent = copy[arrayIndex - 1].indentation;
        if (copy[arrayIndex].indentation <= prevIndent && copy[arrayIndex].indentation <= 3) {
          copy[arrayIndex].indentation += 1;
        }

      }
    }

    // Update the indentation level across the card
    copy = scanIndentation(copy);
    setItems(copy);
  }

  // Change the placement order of the selected item
  function changeOrder(up) {
    let copy = JSON.parse(JSON.stringify(items));
    let selectedList = [...selectedItems];

    // reverse the list of selected items if we are moving down
    if (!up) {
      selectedList = selectedList.reverse();
    }

    // change order of each selected item
    for (let i = 0; i < selectedList.length; i++) {
      const counterId = selectedList[i];
      let arrayIndex = -1;

      // Find the index of this item
      for (let j = 0; j < copy.length; j++) {
        if (copy[j].counterId === counterId) {
          arrayIndex = j;
          break;
        }
      }

      // If we cannot find the index, then return
      if (arrayIndex === -1) {
        console.error("Unable to find item to move");
        continue;
      }

      // Check if we are trying to move up or down the card
      if (up) {
        // if this is not the top item on the card, swap it with the item above it
        if (arrayIndex !== 0) {
          [copy[arrayIndex], copy[arrayIndex - 1]] = [copy[arrayIndex - 1], copy[arrayIndex]];
          copy = scanIndentation(copy);
        } else {
          console.error("Unable to move item as it is already at the edge");
          break;
        }
      } else {
        // if this is not the bottom item on the card, swap it with the item below it
        if (arrayIndex + 1 < copy.length) {
          [copy[arrayIndex], copy[arrayIndex + 1]] = [copy[arrayIndex + 1], copy[arrayIndex]];
          copy = scanIndentation(copy);
        } else {
          console.error("Unable to move item as it is already at the edge");
          break;
        }
      }
    }

    setItems(copy);
  }

  async function handleCreate() {
    // Check for empty inputs
    if (checkInputs()) {
      return;
    }

    // Get the card format from the select
    const formatSelect = document.getElementById("select-new-card-format");
    let newCardFormat = parseInt(formatSelect.options[formatSelect.selectedIndex].value, 10);
    if (document.getElementById("internal-modal-checkbox").checked) {
      newCardFormat += 10;
    }

    // Set the order index of each item and clean up empty strings as needed
    const copy = items;
    for (let i = 0; i < copy.length; i++) {
      copy[i].orderIndex = i;
    }

    // If we are using a preset title apply it now
    let submitTitle = title;
    if (cardTitleMode !== "") {
      submitTitle = cardTitleMode;
    }

    // Get all of the selected files to upload
    const uploadImages = [];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].imageToUpload) {
        uploadImages.push(copy[i].imageToUpload);
      }
    }

    // see if we need to upload any images
    if (uploadImages.length) {
      const formData = new FormData();
      for (let i = 0; i < uploadImages.length; i++) {
        formData.append("images", uploadImages[i]);
      }
      const results = await fetch(`${API_URL}/files/bulk`, {
        method: "POST",
        credentials: "include",
        body: formData
      });

      if (results.ok) {
        const obj = await results.json();
        const urls = obj.urls;

        // update the urls for all of the items
        for (let i = 0; i < urls.length; i++) {
          for (let j = 0; j < copy.length; j++) {
            if (copy[j].imageToUpload) {
              copy[j].imageToUpload = null;
              copy[j].contentUrl = urls[i];
              break;
            }
          }
        }
        setItems(copy);
      } else {
        console.error("Failed to upload images.");
      }
    }

    // see if all graphics are still valid
    for (let i = 0; i < copy.length; i++) {
      const item = copy[i];
      if (item.groupIndex === 2) {
        if (!item.contentUrl.length) {
          setErrorMessage("Error: Invalid file to upload on line " + (i + 1));
          return;
        }
      }
    }

    // Prepare data for new card
    const cardData = {
      headerId: props.headerId,
      cardType: newCardFormat,
      title: submitTitle,
      items: copy
    };

    // Create the new card
    const results = await fetch(`${API_URL}/cards`, {
      method: "POST",
      credentials: "include",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(cardData)
    });

    if (results.ok) {

      const obj = await results.json();

      // give ids and icon type names to each item
      for (let i = 0; i < copy.length; i++) {
        copy[i].itemId = i;
        copy[i].approved = 0;
        for (let j = 0; j < props.iconSet.length; j++) {
          if (props.iconSet[j].iconType === copy[i].iconType) {
            copy[i].typeName = props.iconSet[j].typeName;
            copy[i].color = props.iconSet[j].color;
          }
        }
      }

      const newCard = {
        approved: 0,
        cardId: obj.insertId,
        headerId: props.headerId,
        cardType: newCardFormat,
        title: submitTitle,
        items: [],
        userId: 0,
        created: new Date(),
        orderIndex: obj.insertId,
        tempOrderIndex: null,
        tempCardId: null,
        tempCardType: null,
        tempCreated: null,
        tempUserId: null,
        tempTitle: null,
        tempItems: copy
      };

      props.handleUpdate(newCard, "card", "create");

      // Reset state
      setCounter(0);
      setPureCounter(0);
      setTitle("");
      setFormat(0);
      setItems([]);
      setErrorMessage("");

      // Close modal
      props.handleClose();

    } else {

      // there was an error updating the card
      const obj = await results.json();

      // if the user is performing an unauthorized action
      // log them out and return them to the homepage
      if (results.status === 401) {
        logout();
        window.location.href = "/";
      } else if (results.status === 500 || typeof obj.error === "undefined") {
        setErrorMessage("An internal server error occurred. Please try again later.");
      } else {
        setErrorMessage(obj.error);
      }
    }
  }

  // Submit the current card
  async function handleEdit() {
    // Check for empty inputs
    if (checkInputs()) {
      return;
    }

    // Get the card format from the select
    const formatSelect = document.getElementById("select-new-card-format");
    let newCardFormat = parseInt(formatSelect.options[formatSelect.selectedIndex].value, 10);
    if (document.getElementById("internal-modal-checkbox").checked) {
      newCardFormat += 10;
    }

    // Set the order index of each item and clean up empty strings as needed
    const copy = items;

    for (let i = 0; i < copy.length; i++) {
      copy[i].orderIndex = i;
    }

    // If we are using a preset title apply it now
    let submitTitle = title;
    if (cardTitleMode !== "") {
      submitTitle = cardTitleMode;
    }

    // Get all of the selected files to upload
    const uploadImages = [];
    for (let i = 0; i < copy.length; i++) {
      if (copy[i].imageToUpload) {
        uploadImages.push(copy[i].imageToUpload);
      }
    }

    // see if we need to upload any images
    if (uploadImages.length) {
      const formData = new FormData();
      for (let i = 0; i < uploadImages.length; i++) {
        formData.append("images", uploadImages[i]);
      }
      const results = await fetch(`${API_URL}/files/bulk`, {
        method: "POST",
        credentials: "include",
        body: formData
      });

      if (results.ok) {
        const obj = await results.json();
        const urls = obj.urls;

        // update the urls for all of the items
        for (let i = 0; i < urls.length; i++) {
          for (let j = 0; j < copy.length; j++) {
            if (copy[j].imageToUpload) {
              copy[j].imageToUpload = null;
              copy[j].contentUrl = urls[i];
              break;
            }
          }
        }
        setItems(copy);
      } else {
        console.error("Failed to upload images.");
      }
    }

    // see if all graphics are still valid
    for (let i = 0; i < copy.length; i++) {
      const item = copy[i];
      if (item.groupIndex === 2) {
        if (!item.contentUrl.length) {
          setErrorMessage("Error: Invalid file to upload on line " + (i + 1));
          return;
        }
      }
    }

    // Prepare data for new card
    const cardData = {
      cardType: newCardFormat,
      title: submitTitle,
      items: copy
    };

    // Edit card
    const results = await fetch(`${API_URL}/cards/${props.card.cardId}`, {
      method: "PATCH",
      credentials: "include",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(cardData)
    });

    if (results.ok) {

      // give ids and icon type names to each item
      for (let i = 0; i < copy.length; i++) {
        copy[i].itemId = i;
        copy[i].approved = 0;
        for (let j = 0; j < props.iconSet.length; j++) {
          if (props.iconSet[j].iconType === copy[i].iconType) {
            copy[i].typeName = props.iconSet[j].typeName;
            copy[i].color = props.iconSet[j].color;
          }
        }
      }

      let newCard = {};

      if (props.card.approved) {
        newCard = {
          approved: props.card.approved,
          cardId: props.card.cardId,
          headerId: props.card.headerId,
          cardType: props.card.cardType,
          title: props.card.title,
          items: props.card.items,
          userId: props.card.userId,
          created: props.card.created,
          orderIndex: props.card.orderIndex,
          tempOrderIndex: props.card.orderIndex,
          tempCardId: props.card.cardId,
          tempCardType: newCardFormat,
          tempCreated: new Date(),
          tempUserId: 0,
          tempItems: copy,
          tempTitle: submitTitle
        };
      } else {
        newCard = {
          approved: props.card.approved,
          cardId: props.card.cardId,
          headerId: props.card.headerId,
          cardType: newCardFormat,
          title: submitTitle,
          items: copy,
          userId: 0,
          created: new Date(),
          orderIndex: props.card.orderIndex,
          tempOrderIndex: props.card.tempOrderIndex,
          tempCardId: props.card.tempCardId,
          tempCardType: props.card.tempCardType,
          tempCreated: props.card.tempCreated,
          tempUserId: props.card.tempUserId,
          tempItems: copy,
          tempTitle: props.card.tempTitle
        };
      }

      props.handleUpdate(newCard, "card", "update");

      // Reset state
      setCounter(0);
      setPureCounter(0);
      setTitle("");
      setFormat(0);
      setItems([]);
      setErrorMessage("");

      // Close modal
      props.handleClose();

    } else {

      // there was an error updating the card
      const obj = await results.json();

      // if the user is performing an unauthorized action
      // log them out and return them to the homepage
      if (results.status === 401) {
        logout();
        window.location.href = "/";
      } else if (results.status === 500 || typeof obj.error === "undefined") {
        setErrorMessage("An internal server error occurred. Please try again later.");
      } else {
        setErrorMessage(obj.error);
      }
    }
  }

  // Deletes the selected item(s)
  function deleteItem() {

    if (selectedItems.length > 1) {
      if (!window.confirm("Are you sure you want to delete these items?")) {
        return;
      }
    } else {
      if (!window.confirm("Are you sure you want to delete this item?")) {
        return;
      }
    }

    let copy = [...items];
    let newCount = counter;

    // delete each selected item
    for (let i = 0; i < selectedItems.length; i++) {
      const counterId = selectedItems[i];
      let arrayIndex = -1;

      // Find the index of this item
      for (let j = 0; j < copy.length; j++) {
        if (copy[j].counterId === counterId) {
          arrayIndex = j;
          break;
        }
      }

      // If we can not find the index, then exit
      if (arrayIndex === -1) {
        console.error("Unable to find the item to delete");
        continue;
      }

      // Delete the selected item
      copy.splice(arrayIndex, 1);

      // Update the indentation level across the card
      copy = scanIndentation(copy);
      newCount--;
    }

    setCounter(newCount);
    setItems(copy);
  }

  // Scans through items to ensure they are all indented correctly
  function scanIndentation(itemArray) {

    // The first item in the card can never be indented
    if (itemArray.length) {
      itemArray[0].indentation = 0;
      itemArray[0].maxIndent = 0;
    }
    // Update the indentation of the rest of the items
    for (let i = 1; i < itemArray.length; i++) {
      if (itemArray[i].indentation > itemArray[i - 1].indentation + 1) {
        itemArray[i].indentation = itemArray[i - 1].indentation + 1;
      }
      itemArray[i].maxIndent = itemArray[i - 1].indentation + 1;
    }
    // scan through once more and enforce matching indentation for inline items
    let movedRow = false;
    for (let i = 1; i < itemArray.length; i++) {
      if (itemArray[i].inline && itemArray[i - 1].inline) {
        itemArray[i].indentation = itemArray[i - 1].indentation;
        movedRow = true;
      }
    }
    // if we had to move an inline row of items, do one last pass for indentation
    if (movedRow) {
      for (let i = 1; i < itemArray.length; i++) {
        if (itemArray[i].indentation > itemArray[i - 1].indentation + 1) {
          itemArray[i].indentation = itemArray[i - 1].indentation + 1;
        }
        itemArray[i].maxIndent = itemArray[i - 1].indentation + 1;
      }
    }
    return itemArray;
  }

  // Delete unpublished card changes
  async function handleClear() {

    // Check that the user really wants to delete the changes this version
    if (!window.confirm("This will only delete unpublished versions of this card.\nAre you sure you want to delete this card?")) {
      return;
    }
    if (!window.confirm("Please confirm one final time that you want to delete this card.")) {
      return;
    }

    // delete proposed changes
    const results = await fetch(`${API_URL}/cards/${props.card.cardId}/changes`, {
      method: "DELETE",
      credentials: "include",
      headers: {"Content-Type": "application/json"}
    });

    if (results.ok) {

      const newCard = {
        approved: props.card.approved,
        cardId: props.card.cardId,
        headerId: props.card.headerId,
        cardType: props.card.cardType,
        title: props.card.title,
        items: props.card.items,
        userId: props.card.userId,
        created: props.card.created,
        orderIndex: props.card.orderIndex,
        tempOrderIndex: null,
        tempCardId: null,
        tempCardType: null,
        tempCreated: null,
        tempUserId: null,
        tempItems: [],
        tempTitle: null
      };

      // Reset state
      setCounter(0);
      setPureCounter(0);
      setTitle("");
      setFormat(0);
      setItems([]);
      setErrorMessage("");

      // Close modal
      props.handleClose();


      props.handleUpdate(newCard, "card", "clear");

    } else {

      const obj = await results.json();

      if (results.status === 401) {
        logout();
        window.location.href = "/";
      } else if (results.status === 500 || typeof obj.error === "undefined") {
        setErrorMessage("An internal server error occurred. Please try again later.");
      } else {
        setErrorMessage(obj.error);
      }

    }

  }

  // Delete the current card
  async function deleteCard() {
    // Confirm the user is ready to delete the card
    if (props.role >= 5) {
      if (!window.confirm("This will delete all versions of this card.\nAre you sure you want to delete this card?")) {
        return;
      }
    } else {
      // Just delete unpublished content
      handleClear();
      return;
    }

    if (!window.confirm("Please confirm one final time that you want to delete this card.")) {
      return;
    }

    // Send call to backend to delete card
    const results = await fetch(`${API_URL}/cards/${props.card.cardId}`, {
      method: "DELETE",
      credentials: "include",
      headers: {"Content-Type": "application/json"}
    });

    if (results.ok) {

      const newCard = {
        cardId: props.card.cardId,
        tempCardId: props.card.tempCardId,
        headerId: props.card.headerId
      };

      // Reset state
      setCounter(0);
      setPureCounter(0);
      setTitle("");
      setFormat(0);
      setItems([]);
      setErrorMessage("");

      // Close modal
      props.handleClose();

      props.handleUpdate(newCard, "card", "delete");

    } else {
      setErrorMessage("Error deleting card. Please try again later.");
    }
  }

  // Check for empty inputs (card title, item text/content/labels, icons)
  function checkInputs() {
    let emptyFound = false;
    let newErrorMessage = errorMessage;
    let i = 0;

    // Empty title
    if (!title.length && cardTitleMode === "") {
      emptyFound = true;
      newErrorMessage = "Error: Empty card title";
      if (emptyFound) {
        setErrorMessage(newErrorMessage);
        return true;
      }
    }
    // Empty item array
    if (items.length === 0) {
      setErrorMessage("Error: A card must contain at least one item");
      return true;
    }

    // Empty item text
    for (i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.groupIndex === 1) { // item
        if (item.contentText === "") {
          emptyFound = true;
          newErrorMessage = "Error: Item is not filled out completely on line " + (i + 1);
          break;
        }
      } else if (item.groupIndex === 2) { // graphic
        if ((item.contentUrl === "" && !item.imageToUpload)) {
          emptyFound = true;
          newErrorMessage = "Error: No image selected on line " + (i + 1);
          break;
        }
      } else if (item.groupIndex === 3) { // link
        if (item.contentLabel === "" || (item.contentUrl === "" && !item.imageToUpload)) {
          emptyFound = true;
          newErrorMessage = "Error: Resource is not filled out completely on line " + (i + 1);
          break;
        }
        if (item.contentMode < 0) {
          emptyFound = true;
          newErrorMessage = "Error: Resource link type is not selected on line " + (i + 1);
          break;
        }
      } else if (item.groupIndex === 4) { // text
        if (item.contentText === "") {
          emptyFound = true;
          newErrorMessage = "Error: Text is not filled out on line " + (i + 1);
          break;
        }
      }
      // Check icons
      if (item.iconType === null) {
        emptyFound = true;
        newErrorMessage = "Error: Empty icon on line " + (i + 1);
        break;
      }
    }
    setErrorMessage(newErrorMessage);
    if (emptyFound) { return true; }
    return false;
  }

  // Control input coming from ItemInput for each row according to
  // groupIndex and index in the items state
  function handleInput(e, index, groupIndex) {
    const key = index.toString();
    const copy = [...items];
    if (groupIndex === 1) {
      copy[key].contentText = e;
    } else if (groupIndex === 2) {
      copy[key].contentLabel = e;
    } else if (groupIndex === 3) {
      copy[key].contentUrl = e.target.value;
    } else if (groupIndex === 4) {
      copy[key].contentText = e;
    } else if (groupIndex === 5) {
      copy[key].contentLabel = e.target.value;
    } else if (groupIndex === 6) {
      copy[key].learnMoreUrl = e.target.value;
    }
    setItems(copy);
  }

  // Controls link data changes coming from the item input component
  function handleLinkValue(index, value) {
    const key = index.toString();
    const copy = [...items];
    copy[key].contentMode = value;
    setItems(copy);
  }

  // Controls source data changes coming from the item input component
  function handleSourceValue(index, value) {
    const key = index.toString();
    const copy = [...items];
    copy[key].sourceId = value;
    setItems(copy);
  }

  // Updates the an item's icon
  function updateIcon(icon, index) {
    const copy = [...items];
    copy[index].iconType = icon;
    setItems(copy);
  }

  // Gets the index of the icon in the appropriate icon array
  function getIconIndex(id, groupIndex) {
    let i;
    if (groupIndex === 3) {
      for (i = 0; i < linkIcons.length; i++) {
        if (linkIcons[i].iconType === id) {
          return i;
        }
      }
    } else if (groupIndex === 2) {
      for (i = 0; i < imageIcons.length; i++) {
        if (imageIcons[i].iconType === id) {
          return i;
        }
      }
    } else {
      for (i = 0; i < basicIcons.length; i++) {
        if (basicIcons[i].iconType === id) {
          return i;
        }
      }
    }
    return null;
  }

  // Returns a list of icons based on the type of item
  function getIcons(groupIndex) {
    if (groupIndex === 3) {
      return linkIcons;
    } else if (groupIndex === 2) {
      return imageIcons;
    } else {
      return basicIcons;
    }
  }

  // Copy item
  function copyItem() {
    const copy = [...items];
    let itemString = "";

    // copy each selected item
    for (let i = 0; i < selectedItems.length; i++) {
      const counterId = selectedItems[i];
      let arrayIndex = -1;
      let item = {};

      // Find the index of this item
      for (let j = 0; j < copy.length; j++) {
        if (copy[j].counterId === counterId) {
          item = copy[j];
          arrayIndex = j;
          break;
        }
      }

      // If we can not find the index, then exit
      if (arrayIndex === -1) {
        console.error("Unable to find item to copy");
        continue;
      }

      // stringify the item data
      if (itemString.length) {
        itemString += "!!@@#@@!!";
      }
      itemString += item.contentText + "$%$" + item.contentLabel + "$%$" +
        item.contentUrl + "$%$" + item.iconType + "$%$" + item.groupIndex + "$%$" +
        item.contentMode + "$%$" + item.internal + "$%$" + item.sourceId + "$%$" +
        item.inline + "$%$" + item.learnMoreUrl;
    }

    // show the toast stating that we have copied an item
    setCopyToast(true);

    // save the items to local storage
    window.localStorage.setItem("itemCopy", itemString);
  }

  // Paste item
  function pasteItem() {
    let copy = [...items];
    let newCounter = counter;
    let pureId = pureCounter;

    // retrieve the item from local storage
    const newItem = window.localStorage.getItem("itemCopy");
    if (newItem === null) {
      setErrorMessage("No item to paste from clipboard");
      return;
    }

    // split each item into an array
    const fullItemArray = newItem.split("!!@@#@@!!");

    // split each item by its properties
    for (let i = 0; i < fullItemArray.length; i++) {
      const itemArray = fullItemArray[i].split("$%$");

      // add the item to the card
      const key = (newCounter).toString();

      // create the new item
      copy[key] = {};
      copy[key].counterId = pureId + 1;
      copy[key].contentText = itemArray[0];
      copy[key].contentLabel = itemArray[1];
      copy[key].contentUrl = itemArray[2];
      copy[key].iconType = parseInt(itemArray[3], 10);
      copy[key].groupIndex = parseInt(itemArray[4], 10);
      copy[key].contentMode = parseInt(itemArray[5], 10);
      copy[key].internal = parseInt(itemArray[6], 10);
      copy[key].sourceId = parseInt(itemArray[7], 10);
      copy[key].inline = parseInt(itemArray[8], 10);
      copy[key].indentation = 0;
      copy[key].learnMoreUrl = itemArray[9];

      // Make sure the indentation is up to date
      copy = scanIndentation(copy);

      newCounter++;
      pureId += newCounter + 1;
    }

    // save the new item information
    setItems(copy);
    setCounter(newCounter);
    setPureCounter(pureId);
  }

  // Closes the specified toast
  function closeToast() {
    setCopyToast(false);
  }

  // Updates the current card title when the dropdown is changed
  function updateCardTitle() {
    const titleSelect = document.getElementById("card-title-dropdown");
    const newCardValue = titleSelect.options[titleSelect.selectedIndex].value;
    setCardTitleMode(newCardValue);
  }

  // Toggle the internal status of an item
  function toggleInternal() {
    const copy = [...items];

    // make each item internal
    for (let i = 0; i < selectedItems.length; i++) {
      const counterId = selectedItems[i];
      let arrayIndex = -1;

      // Find the item
      for (let j = 0; j < copy.length; j++) {
        if (copy[j].counterId === counterId) {
          if (copy[j].internal === 1) {
            copy[j].internal = 0;
          } else {
            copy[j].internal = 1;
          }
          arrayIndex = j;
          break;
        }
      }

      // If we can not find the index, then exit
      if (arrayIndex === -1) {
        console.error("Unable to find item change internal status");
      }
    }

    setItems(copy);
  }

  // Toggle inline displaying of an item
  function toggleInline() {
    let copy = [...items];

    // make each item inline
    for (let i = 0; i < selectedItems.length; i++) {
      const counterId = selectedItems[i];
      let arrayIndex = -1;

      // Find the item
      for (let j = 0; j < copy.length; j++) {
        if (copy[j].counterId === counterId) {
          if (copy[j].inline === 1) {
            copy[j].inline = 0;
          } else {
            copy[j].inline = 1;
          }
          arrayIndex = j;
          break;
        }
      }

      // Make sure the indentation is up to date
      copy = scanIndentation(copy);

      // If we can not find the index, then exit
      if (arrayIndex === -1) {
        console.error("Unable to find item change inline status");
      }
    }

    setItems(copy);
  }

  // handle storing file information for uploaded images
  function handleNewImage(newImage, index) {
    const key = index.toString();
    const copy = [...items];
    copy[key].imageToUpload = newImage;
    setItems(copy);

    if (!imageAgreement) {
      setShowAgreement(true);
    }
  }

  // when the user cancels an image upload agreement
  function cancelAgreement() {
    const copy = [...items];

    // clear all of the images to upload for all items
    for (let i = 0; i < copy.length; i++) {
      copy[i].imageToUpload = null;
      if (copy[i].groupIndex === 2) {
        const imageInput = document.getElementById(`custom-file-upload-${i}`);
        imageInput.value = "";
        const inputEvent = new Event("input", {bubbles: true});
        imageInput.dispatchEvent(inputEvent);
      }
    }

    setItems(copy);
    setShowAgreement(false);
  }

  // when the user accepts an image upload agreement
  function acceptAgreement() {
    setShowAgreement(false);
    setImageAgreement(true);
  }

  // handle selecting one or more items
  function itemSelection(targetItem) {
    if (controlHeld && selectedItems.length) {

      // don't allow selecting the same item more than once
      for (let i = 0; i < selectedItems.length; i++) {
        if (selectedItems[i] === targetItem) {
          return;
        }
      }

      reorderSelected([...selectedItems, targetItem]);

    } else {
      setSelectedItems([targetItem]);
    }
  }

  return (
    <div className="text-center mx-2">

      <Agreement
        agreementTitle={"Image Agreement"}
        agreementName={"image"}
        terms={imageTerms}
        acceptFunction={() => acceptAgreement()}
        show={showAgreement}
        closeModal={() => cancelAgreement()}
      />

      <Toast show={copyToast} text={selectedItems.length > 1 ? "Items copied" : "Item copied"} handleClose={() => closeToast()} />

      <Modal show={props.show} onHide={() => props.handleClose()} dialogClassName="modal-width">
        <Modal.Header>
          <h5 className="modal-title font-weight-bold" id="exampleModalLabel">{props.edit ? "Edit Card" : "Create Card"}</h5>
          <Button variant="none" onClick={() => props.handleClose()}>
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>

          <Row>
            <Col>
              <Form.Group controlId="cardTitleDropdown">
                <Form.Label className="font-weight-bold">Card Title</Form.Label>
                <select className="form-control"
                  id="card-title-dropdown"
                  defaultValue="0"
                  onChange={() => updateCardTitle()}
                >
                  <option value="">Custom</option>
                  {props.cardTitles.map((title) =>
                    <option key={title.titleId} value={title.title}>{title.title}</option>
                  )}
                </select>
              </Form.Group>
            </Col>
          </Row>

          {cardTitleMode === "" ? (
            <Row>
              <Col>
                <Form.Group controlId="formTitle">
                  <Form.Label className="font-weight-bold">Custom Card Title</Form.Label>
                  <Form.Control type="text" maxLength="100" defaultValue={title} onChange={(e) => setTitle(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>
          ) : (
            null
          )}

          <Row>
            <Col>
              <Form.Group controlId="formFormat">
                <Form.Label className="font-weight-bold">Card Format</Form.Label>
                <select className="form-control"
                  id="select-new-card-format"
                  defaultValue={format}
                >
                  <option value="0">Default</option>
                  <option value="1">Thumbnail Gallery</option>
                  <option value="2">Expandable List</option>
                </select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="custom-control form-control-lg custom-checkbox my-2">
                {checked ? (
                  <input type="checkbox" className="form-check-input custom-control-input"
                    id="internal-modal-checkbox" onClick={() => setChecked(0)} defaultChecked
                  />
                ) : (
                  <input type="checkbox" className="form-check-input custom-control-input"
                    id="internal-modal-checkbox"
                  />
                )}
                <label className="form-check-label custom-control-label font-weight-bold pl-3" htmlFor="internal-modal-checkbox">
                  Internal (not viewable by the public)
                </label>
              </div>
            </Col>
          </Row>

          <div className="font-weight-bold mb-2">Items</div>

          <div className="item-button-bar card sticky-top py-2 px-2 mb-3">
            <div>
              <button className="btn btn-danger btn ml-2 my-1"
                onClick={() => deleteItem()}
              >
                <i className="fas fa-fw fa-times mr-2 my-1" />
                Delete
              </button>

              <button className="btn btn-info internal-item-button btn ml-2"
                onClick={() => toggleInternal()}
              >
                <i className="fas fa-fw fa-unlock mr-2 my-1" />
                Internal
              </button>

              <button className="btn btn-info inline-item-button btn ml-2"
                onClick={() => toggleInline()}
              >
                <i className="fas fa-fw fa-ellipsis-h mr-2 my-1" />
                Inline
              </button>

              <button className="btn btn-info copy-paste-button btn ml-2"
                onClick={() => copyItem()}
              >
                <i className="fas fa-fw fa-copy mr-2 my-1" />
                Copy
              </button>

              <button className="btn btn-primary btn ml-2 my-1"
                onClick={() => changeIndent(-1)}
              >
                <i className="fas fa-fw fa-minus mr-2 my-1" />
                Unindent
              </button>

              <button className="btn btn-primary btn ml-2 my-1"
                onClick={() => changeIndent(1)}
              >
                <i className="fas fa-fw fa-plus mr-2" />
                Indent
              </button>

              <button className="btn btn-success btn ml-2 my-1"
                onClick={() => changeOrder(true)}
              >
                <i className="fas fa-fw fa-arrow-up mr-2 my-1" />
                Move Up
              </button>

              <button className="btn btn-success btn ml-2 my-1"
                onClick={() => changeOrder(false)}
              >
                <i className="fas fa-fw fa-arrow-down mr-2" />
                Move Down
              </button>
            </div>
          </div>

          {/* Item Input Fields */}
          {items.map((item, i) =>
            <Row
              className={`mb-2 mx-2 ${isSelected(item) ? "modal-selected-item" : ""}
                ${item.internal ? "internal-modal-item" : ""} ${item.inline ? "inline-modal-item" : ""}`}
              key={item.counterId}
              onClick={() => itemSelection(item.counterId)}
            >
              <div className="input-group">
                <Indent indentLevel={item.indentation} />
                <IconDropdown
                  itemId={item.itemId}
                  icons={getIcons(item.groupIndex)}
                  iconIndex={getIconIndex(item.iconType, item.groupIndex)}
                  onIconChange={(icon) => updateIcon(icon, i)}
                />
                <ItemInput
                  title="Text"
                  maxLength="1000"
                  onNewImage={(newImage, index) => handleNewImage(newImage, index)}
                  handleInput={(e1, e2, e3) => handleInput(e1, e2, e3)}
                  handleLinkValue={(e1, e2) => handleLinkValue(e1, e2)}
                  handleSourceValue={(e1, e2) => handleSourceValue(e1, e2)}
                  index={i}
                  value={item}
                  groupIndex={item.groupIndex}
                  internal={item.internal}
                  inline={item.inline}
                  sourceId={item.sourceId}
                  sources={props.sources}
                  isSelected={() => isSelected(item)}
                />
              </div>
            </Row>
          )}

          <Row>
            <Col className="mt-2">
              <AddButton variant="info" label="Add Item" onClick={() => incrementCounter(1)} />
              <AddButton variant="success" label="Add Graphic" onClick={() => incrementCounter(2)} />
              <AddButton variant="primary" label="Add Site Resource" onClick={() => incrementCounter(3)} />
              <Button
                onClick={() => pasteItem()}
                className="mr-2 copy-paste-button"
                variant="info"
              >
                <i
                  className='fas fa-paste text-white mr-2'
                  style={{transform: "scale(1.5)"}}
                />
                Paste
              </Button>
            </Col>
          </Row>

          <Row>
            <div className='col-3' />
            <div className='col-6 mt-4'>
              <Error
                message={errorMessage}
              />
            </div>
          </Row>

        </Modal.Body>

        <Modal.Footer className="modal-footer">

          {props.edit ? (
            <Fragment>
              <Button
                className="mr-auto"
                variant="danger"
                onClick={() => deleteCard()}
              >
                {props.role >= 5 ? (
                  <span>Delete Card</span>
                ) : (
                  <span>Delete Unpublished Card</span>
                )}
              </Button>
              <Button variant="primary" onClick={() => handleEdit()}>Submit Card Changes</Button>
            </Fragment>
          ) : (
            <Button variant="primary" onClick={() => handleCreate()}>Submit Card</Button>
          )}
          <Button variant="secondary" onClick={() => props.handleClose()}>Cancel</Button>

        </Modal.Footer>
      </Modal>
    </div>
  );

}
export default ConstructCardModal;

ConstructCardModal.propTypes = {
  edit: PropTypes.bool,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  card: PropTypes.object,
  handleUpdate: PropTypes.func,
  iconSet: PropTypes.array,
  headerId: PropTypes.number,
  sources: PropTypes.array,
  cardTitles: PropTypes.array,
  role: PropTypes.number
};
