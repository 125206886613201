import React, {useState, useEffect, Fragment} from "react";
import {Modal, Button, Row, Col, Form, Card} from "react-bootstrap";
import {logout} from "../../utilities/cookieAuth";
import {API_URL} from "../../utilities/constants";
import PropTypes from "prop-types";
import Error from "../General/Error";
import {NavLink} from "react-router-dom";
import "./EditCategory.css";

// button and modal for editing a category
function EditCategory(props) {

  const [singleName, setSingleName] = useState("");
  const [pluralName, setPluralName] = useState("");
  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [checked, setChecked] = useState(0);

  // If the category is edited, make sure we keep the modal up to date
  useEffect(() => {
    setSingleName(props.category.singleName);
    setPluralName(props.category.pluralName);
    setDescription(props.category.description);
    setChecked(props.category.internal);
  }, [props.category.singleName, props.category.pluralName,
    props.category.description, props.category.internal]);

  function handleClose() {
    setShow(false);
    setErrorMessage("");
  }

  function handleShow() {
    setShow(true);
  }

  async function handleSubmit() {
    // Check for empty inputs
    if (checkInputs()) {
      return;
    }

    let internal = 0;
    if (document.getElementById("internal-modal-checkbox").checked) {
      internal = 1;
    }

    // Prepare data
    const data = {
      singleName: singleName,
      pluralName: pluralName,
      description: description,
      internal: internal
    };

    // update a category
    const results = await fetch(`${API_URL}/categories/${props.category.categoryId}`, {
      method: "PATCH",
      credentials: "include",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });

    if (results.ok) {

      // Reset state
      setSingleName("");
      setPluralName("");
      setDescription("");
      setErrorMessage("");
      setChecked(0);

      // Close modal
      handleClose();

      // Reload sidebar after adding
      props.refresh();

    } else {

      const obj = await results.json();

      // if the user is performing an unauthorized action
      // log them out and return them to the homepage
      if (results.status === 401) {
        logout();
        window.location.href = "/";
      } else if (results.status === 500 || typeof obj.error === "undefined") {
        setErrorMessage("An internal server error occurred. Please try again later.");
      } else {
        setErrorMessage(obj.error);
      }

    }

  }

  // Check for empty inputs in state before submission
  // True if empty inputs found, false if all inputs filled
  function checkInputs() {
    let emptyFound = false;
    let newErrorMessage = errorMessage;
    // Empty description
    if (!description.length) {
      emptyFound = true;
      newErrorMessage = "Error: Empty description";
    }
    // Empty summary
    if (!pluralName.length) {
      emptyFound = true;
      newErrorMessage = "Error: Empty plural name";
    }
    // Empty name
    if (!singleName.length) {
      emptyFound = true;
      newErrorMessage = "Error: Empty singular name";
    }
    setErrorMessage(newErrorMessage);
    if (emptyFound) { return true; }
    return false;
  }

  // delete the category
  async function deleteCategory() {
    // Confirm the user is ready to delete the category
    if (!window.confirm("This will delete the category.\nAre you sure you want to delete this category?")) {
      return;
    }

    if (!window.confirm("Please confirm one final time that you want to delete this category.")) {
      return;
    }

    const results = await fetch(`${API_URL}/categories/${props.category.categoryId}`, {
      method: "DELETE",
      credentials: "include",
      headers: {"Content-Type": "application/json"}
    });

    if (results.ok) {

      // Reset state
      setErrorMessage("");

      // Close modal
      handleClose();

      // refresh the page
      window.location.reload();

    } else {

      const obj = await results.json();

      if (results.status === 401) {
        logout();
        window.location.href = "/";
      } else if (results.status === 500 || typeof obj.error === "undefined") {
        setErrorMessage("An internal server error occurred. Please try again later.");
      } else {
        setErrorMessage(obj.error);
      }
    }
  }

  return props.role >= 5 ? (
    <div className={`${props.navbar ? "d-inline" : "w-100"}`}>

      {/* The style of the button depends if this is the sidebar or navbar */}
      {props.navbar ? (
        <Fragment>
          <div className="navbar-item px-2 py-1" onClick={(e) => handleShow(e)}>
            <i
              className="nav-bar-icon fas fa-plus-circle text-info mr-2"
            />
            <span className="navbar-item-text text-left">Edit Category</span>
          </div>
        </Fragment>
      ) : (
        <NavLink to="#" className="ml-3 sidebar-nav-link">
          <Card.Body className="sidebar-nav-link" onClick={() => handleShow()}>
            <i
              className="nav-bar-icon fas fa-plus-circle text-info mr-2"
            />
            <span className="navbar-item-text text-left">            Edit Category</span>
          </Card.Body>
        </NavLink>
      )}

      <Modal show={show} onHide={() => handleClose()} dialogClassName="modal-width">
        <Modal.Header>
          <h5 className="modal-title font-weight-bold" id="exampleModalLabel">Edit Category</h5>
          <Button variant="none" onClick={() => handleClose()}>
            <span aria-hidden="true">&times;</span>
          </Button>

        </Modal.Header>

        <Modal.Body >

          <Row>
            <Col>
              <Form.Group controlId="formName">
                <Form.Label className="font-weight-bold">Singular Name</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="100"
                  placeholder="Enter singular name"
                  onChange={(e) => setSingleName(e.target.value)}
                  defaultValue={singleName}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="formPlural">
                <Form.Label className="font-weight-bold">Plural Name</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="100"
                  placeholder="Enter plural name"
                  onChange={(e) => setPluralName(e.target.value)}
                  defaultValue={pluralName}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="formDescription">
                <Form.Label className="font-weight-bold">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength="1000"
                  rows="4"
                  placeholder="Enter description"
                  onChange={(e) => setDescription(e.target.value)}
                  defaultValue={description}
                  style={{
                    maxHeight: "500px"
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="custom-control form-control-lg custom-checkbox my-2">
                {checked ? (
                  <input type="checkbox" className="form-check-input custom-control-input"
                    id="internal-modal-checkbox" onClick={() => setChecked(0)} checked
                  />
                ) : (
                  <input type="checkbox" className="form-check-input custom-control-input"
                    id="internal-modal-checkbox"
                  />
                )}
                <label className="form-check-label custom-control-label font-weight-bold pl-3" htmlFor="internal-modal-checkbox">
                  Internal (not viewable by the public)
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <div className='col-3' />
            <div className='col-6 mt-2'>
              <Error
                message={errorMessage}
              />
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <Button
            className="mr-auto"
            variant="danger"
            onClick={() => deleteCategory()}
          >
            <span>Delete Category</span>
          </Button>
          <Button variant="secondary" onClick={() => handleClose()}>Close</Button>
          <Button variant="primary" onClick={(e) => handleSubmit(e)}>Submit Category</Button>
        </Modal.Footer>
      </Modal>
    </div >
  ) : (
    null
  );

}
export default EditCategory;

EditCategory.propTypes = {
  category: PropTypes.object,
  role: PropTypes.number,
  refresh: PropTypes.func,
  navbar: PropTypes.bool
};